<template>
  <b-modal id="invitationsentmodal" modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div class="text-center">
      <div class="invitation-emoji">🥳</div>
      <h1 class="mt-5" v-if="userInvitationModalData && userInvitationModalData.emailSent">{{ $t('invitation_sent') }}</h1>
      <h1 class="mt-5" v-if="userInvitationModalData && !userInvitationModalData.emailSent">{{ $t('password_changed') }}</h1>
      <div v-if="userInvitationModalData && userInvitationModalData.emailSent">
        <p class="text-muted mt-4" v-if="user == 'student'">{{ $t('invitation_sent_to_student') }}</p>
        <p class="text-muted mt-4" v-if="user == 'teacher'">{{ $t('invitation_sent_to_teacher') }}</p>
      </div>
      <div v-if="userInvitationModalData" class="mt-4 card card-gray text-left mb-4">
        <h2>{{ $t('login_details') }}</h2>
        <div class="label">{{ $t('login_page') }}</div>
        <div class="value">{{ domain }}/login</div>
        <div class="label">{{ $t('login') }}</div>
        <div class="value">{{ userInvitationModalData.login }}</div>
        <div class="label">{{ $t('password') }}</div>
        <div class="value">{{ userInvitationModalData.password }}</div>  
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button class="btn-themed" @click="hide">{{ $t('done') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import {mapState} from "vuex"

export default {
  components: {
    CloseSvg
  },
  computed: {
    ...mapState({
      userInvitationModalData: state => state.userInvitationModalData,
    }),
    domain() {
      return process.env.VUE_APP_FRONT_DOMAIN
    }
  },
  data() {
    return {

    }
  },
  props: {
    user: { type: String }
  },
  mounted() {

  },
  methods: {
    hide() {
      this.$bvModal.hide('invitationsentmodal')
    }
  }
}
</script>

<style scoped lang="scss">
.invitation-emoji {
  margin-top: 60px;
  font-size: 60px;
}
.card-gray {
  background: #F8F9FB;
  border-radius: 16px;
  border: none;
  padding: 32px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 32px;
  }
  .label {
    color: #9B9FA9;
    font-size: 14px;
  }
  .value {
    font-size: 16px;
    color: #000000;
    margin-bottom: 24px;
  }
}
</style>


